import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'

export default function LoginForm() {
  return (
    <Container>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
        <Card>
          <Card.Header>Login in to continue ...</Card.Header>
          <Card.Body>
            <Card.Text>
              <div id="firebaseui-auth-container"> </div>
            </Card.Text>
          </Card.Body>
          </Card>
        </Col>
        </Row>
    </Container>
  )
}