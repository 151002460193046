import React from 'react'
import HomePage from './containers/HomePage'
import * as firebaseui from 'firebaseui'
import firebase from 'firebase/app'
import apiCall from './helpers/API'
import { firebaseUiConfig } from './helpers/firebase'
import LoginForm from  './containers/FirebaseLogin/LoginForm'



export class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isUserLogged: false,
      currentUser: null
    }
  }

  componentDidMount = () => {
    const self = this
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        apiCall.playerByEmail({ email: user.email }).then(user => {
          self.setState({
            loading: false,
            isUserLogged: true,
            currentUser: user
          })
        })
        const { email } = user
        const emailDomain = email.substring(email.lastIndexOf('@') + 1)
        if (emailDomain !== apiCall.allowedEmailDomain) {
          console.log('your domain ->>', emailDomain)
          self.setState({
            loading: false,
            isUserLogged: false
          })
          return false
        }
      } else {
        self.setState({
          loading: false,
          isUserLogged: false
        })
      }
    })
    // Initialize the FirebaseUI Widget using Firebase.
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth())

    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', firebaseUiConfig({}))
  }

  render() {
    if(this.state.currentUser) {
      return <HomePage name={this.props.name} />
    } else {
      return <LoginForm/>
    }
  }
}

export default App
